import React from "react"
import { Helmet } from "react-helmet"

import '../styles/App.scss';
 
import Home from ".";

export default function lost() {
  return Home();
}
